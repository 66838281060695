import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/views/Home.vue';
import Privacy from '@/views/Privacy.vue';
import Terms from '@/views/Terms.vue';
import Download from '@/views/Download.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/privacy-policy',
    name: 'Privacy',
    component: Privacy,
  },
  {
    path: '/terms-conditions',
    name: 'Terms',
    component: Terms,
  },
  {
    path: '/download',
    name: 'Download',
    component: Download,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;

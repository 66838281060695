
import Vue from 'vue';
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';

export default Vue.extend({
  components: {
    NavBar, Footer,
  },
});


import { defineComponent } from 'vue';

// eslint-disable-next-line no-shadow
enum OperatingSystem {
  Android = 'Android',
  iOS = 'iOS',
}

export default defineComponent({
  name: 'Download',
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      isLoading: true,
    };
  },
  methods: {
    getMobileOperatingSystem(): OperatingSystem | null {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

      // Windows Phone must come first because its UA also contains 'Android'
      if (/windows phone/i.test(userAgent)) {
        return null;
      }

      if (/android/i.test(userAgent)) {
        return OperatingSystem.Android;
      }

      // iOS detection from: https://stackoverflow.com/a/58065241
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !(window as any).MSStream) {
        return OperatingSystem.iOS;
      }

      return null;
    },
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted() {
    const os = this.getMobileOperatingSystem();

    switch (os) {
      case OperatingSystem.Android:
        window.location.href = 'https://play.google.com/store/apps/details?id=swiper.waffle.swiper';
        break;
      case OperatingSystem.iOS:
        window.location.href = 'https://apps.apple.com/us/app/swiper-app/id1551695733';
        break;
      default:
        // TODO: show an error message
        this.$router.push('/');
        break;
    }
  },
});


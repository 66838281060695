<template>
    <footer class="footer">
        <div class="columns is-centered">
            <div class="column has-text-centered">
                <b-button
                    size="small"
                    type="is-primary"
                    tag="router-link"
                    :to="{ name: 'Privacy' }">
                        Privacy Policy
                </b-button>
                <br/>
                <b-button
                    class="mt-1"
                    size="small"
                    type="is-primary"
                    tag="router-link"
                    :to="{ name: 'Terms' }">
                        Terms and Conditions
                </b-button>
                <p>&copy; Swiper 2021</p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {

};
</script>

<style>

</style>
